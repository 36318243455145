<template>
  <Modal v-model="showInventoryDetail" width="1000" title="设置媒体库商品规格" footer-hide>
    <div class="workplatform-title m-b-10">商品基础信息</div>
    <Row class="p-b-5" :gutter="8">
      <i-col span="12">
        <label class="title inline" style="width: 80px;">编号 </label>
        {{inventoryBean.code}}
      </i-col>
      <i-col span="12">
        <label class="title inline" style="width: 80px;">站点 </label>
        {{inventoryBean.stationName}}
      </i-col>
      <i-col span="8">
        <label class="title inline" style="width: 80px">类型 </label>
        {{inventoryBean.resourcetypeName}}
      </i-col>
    </Row>

    <!-- <div class="workplatform-title m-b-10 m-t-20">媒体服务项</div>
    <Row class="p-b-5" :gutter="8">
      <i-col span="24" >
        <CheckboxGroup v-model="checkServiceItem">
            <Checkbox v-for="item in serviceItems" :key="item.id" :label="item.id">{{ item.name }}（{{ formatMoney(item.price) }} / {{ item.unitName }}）</Checkbox>
        </CheckboxGroup>
      </i-col>
    </Row> -->

    <!-- <div class="workplatform-title m-b-10 m-t-20">媒体档期售价</div>
    <template v-if="isElectronic">
      <Row :gutter="2" class="table-title m-b-5">
        <i-col span="3">投放天数</i-col>
        <i-col span="3">投放规格(秒)</i-col>
        <i-col span="3">投放次数</i-col>
        <i-col span="4" class="text-center">标价</i-col>
        <i-col span="4" class="text-center">售价</i-col>
        <i-col span="4" class="text-center">结算价</i-col>
        <i-col span="2" class="text-center">是否投放</i-col>
        <i-col v-if="resourceSchedulePrice.length>1" span="1" class="text-right">操作</i-col>
      </Row>
      <Row :gutter="2" v-for="(item,index) in resourceSchedulePrice" :key="index" class="m-b-5">
        <i-col span="3">
          <InputNumber v-model="item.day" size="small" :max="999999" :min="1" style="width: 100px;" placeholder="请输入投放天数"></InputNumber>
        </i-col>
        <i-col span="3">
          <InputNumber v-model="item.duration" size="small" :max="999999" :min="1" style="width: 100px;" placeholder="请输入投放规格"></InputNumber>
        </i-col>
        <i-col span="3">
          <InputNumber v-model="item.period" size="small" :max="999999" :min="1" style="width: 100px;" placeholder="请输入投放次数"></InputNumber>
        </i-col>
        <i-col span="4" class="text-center">
          <InputNumber v-model="item.originalPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
        </i-col>
        <i-col span="4" class="text-center">
          <InputNumber v-model="item.sellingPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
        </i-col>
        <i-col span="4" class="text-center">
          <InputNumber v-model="item.statementPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
        </i-col>
        <i-col span="2" class="text-center">
          <Icon v-if="item.status>0" type="md-checkmark" color="#19be6b" size="18" />
          <span v-else>-</span>
        </i-col>
        <i-col v-if="resourceSchedulePrice.length>1" span="1" class="text-center">
          <a class="text-red" @click="handleDeleteElecSchedule(index)">移除</a>
        </i-col>
      </Row>
      <p><a @click="handleAddSchedulePrice"><Icon type="md-add" />添加新的售卖规格</a></p>

    </template>

    <template v-else>
      <Row :gutter="8" class="table-title m-b-5">
        <i-col span="2"></i-col>
        <i-col span="3">标价</i-col>
        <i-col span="3">售价</i-col>
        <i-col span="3">结算价</i-col>
        <i-col span="1" class="text-center">投放</i-col>
        <i-col span="2"></i-col>
        <i-col span="3">标价</i-col>
        <i-col span="3">售价</i-col>
        <i-col span="3">结算价</i-col>
        <i-col span="1" class="text-center">投放</i-col>
      </Row>

      <Row :gutter="2" v-for="index in (resourceSchedulePrice.length/2)" :key="index">
        <i-col span="2" class="m-b-5 text-center">{{ resourceSchedulePrice[index*2-2].scheduleName }}</i-col>
        <i-col span="3" class="m-b-5">
          <InputNumber v-model="resourceSchedulePrice[index*2-2].originalPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
        </i-col>
        <i-col span="3" class="m-b-5">
          <InputNumber v-model="resourceSchedulePrice[index*2-2].sellingPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
        </i-col>
        <i-col span="3" class="m-b-5">
          <InputNumber v-model="resourceSchedulePrice[index*2-2].statementPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的结算价格"></InputNumber>
        </i-col>
        <i-col span="1" class="m-b-5 text-center">
          <Icon v-if="resourceSchedulePrice[index*2-2].status>0" type="md-checkmark" color="#19be6b" size="18" />
          <span v-else>-</span>
        </i-col>

        <i-col span="2" class="m-b-5 text-center">{{ resourceSchedulePrice[index*2-1].scheduleName }}</i-col>
        <i-col span="3" class="m-b-5">
          <InputNumber v-model="resourceSchedulePrice[index*2-1].originalPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
        </i-col>
        <i-col span="3" class="m-b-5">
          <InputNumber v-model="resourceSchedulePrice[index*2-1].sellingPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
        </i-col>
        <i-col span="3" class="m-b-5">
          <InputNumber v-model="resourceSchedulePrice[index*2-1].statementPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的结算价格"></InputNumber>
        </i-col>
        <i-col span="1" class="m-b-5 text-center">
          <Icon v-if="resourceSchedulePrice[index*2-1].status>0" type="md-checkmark" color="#19be6b" size="18" />
          <span v-else>-</span>
        </i-col>
      </Row>
    </template>

    <Row class="text-center">
      <i-col span="24">
        <i-button class="m-t-10" type="primary" style="width: 150px;" @click="handleSave">保存并提交</i-button>
      </i-col>
    </Row> -->
  </Modal>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getCloudInventory, updateInventoryList } from '@/api/product/cloudinventory'

export default {
  data () {
    return {
      showInventoryDetail: false,
      inventoryBean: {}
    }
  },
  methods: {
    showModal (params) {
      this.showInventoryDetail = true
      this.inventoryBean = params
    },
    initData () {
      const editBean = {
        relateId: this.inventoryBean.id,
        type: 1
      }

      getCloudInventory(editBean).then(res => {
        this.resourceSchedulePrice = res
      })
    },
    getResourceStatus (enabled) {
      const status = this.statusArray.find(x => x.value === enabled)
      return status ? status.name : '/'
    },
    handleSave () {
      // 如果是电子屏，那么需要格式化所有的scheduleName
      if (this.isElectronic) {
        this.resourceSchedulePrice.forEach(element => {
          element.scheduleName = element.day + '天'
        })
      }

      updateInventoryList(this.resourceSchedulePrice).then(res => {
        this.$Notice.success({ desc: '保存媒体价格成功！' })
        this.showResourceDetail = false
      })
    },
    handleAddSchedulePrice () {
      const defaultPrice = this.resourceSchedulePrice[0]

      this.resourceSchedulePrice.push({
        day: 7,
        duration: 5,
        enabled: true,
        inventoryId: defaultPrice.inventoryId,
        originalPrice: 0.01,
        period: 20,
        resourceId: defaultPrice.resourceId,
        scheduleName: '7天',
        sellingPrice: 0.01,
        statementPrice: 0.01,
        skuId: -1,
        status: 0
      })
    },
    handleDeleteElecSchedule (deleteIndex) {
      this.resourceSchedulePrice.splice(deleteIndex, 1)
    },
    formatMoney (number) {
      return toMoney(number)
    }
  }
}
</script>
